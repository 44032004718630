import rp1 from 'assets/images/wildlife-sighted-hyderabad/DSC4526.png';
import rp2 from 'assets/images/wildlife-sighted-hyderabad/IMG_0013.png';
import rp3 from 'assets/images/wildlife-sighted-hyderabad/20190501_MON_3308.png';
import rp4 from 'assets/images/wildlife-sighted-hyderabad/IMG_5945.png';
import rp5 from 'assets/images/wildlife-sighted-hyderabad/Nursery.png';
import rp6 from 'assets/images/wildlife-sighted-hyderabad/Rain_forest.png';

export const hyderabadWildlifeImg = [
  {
    id: 1,
    img: rp1,
    alt: '',
  },
  {
    id: 2,
    img: rp2,
    alt: '',
  },
  {
    id: 3,
    img: rp3,
    alt: '',
  },
  {
    id: 4,
    img: rp4,
    alt: '',
  },
  {
    id: 5,
    img: rp5,
    alt: '',
  },
  {
    id: 6,
    img: rp6,
    alt: '',
  },
];

export const timelapseImagery = [
  {
    id: 1,
    img: rp4,
    alt: 'Picture taken on plantation day',
  },
  {
    id: 2,
    img: rp5,
    alt: 'Picture taken 3 months after',
  },
  {
    id: 3,
    img: rp6,
    alt: 'Picture taken 6 months after',
  },
  {
    id: 4,
    img: rp1,
    alt: 'Picture taken 6 months after',
  },
  {
    id: 5,
    img: rp2,
    alt: 'Picture taken 6 months after',
  },
  {
    id: 6,
    img: rp3,
    alt: 'Picture taken 6 months after',
  },
];

export const hyderabadListofSpices = [
  {
    "id": 1,
    "scientific_name": "Syzygium travancoricum",
    "common_name": "Poriyal",
    "icun_assessment": "Critically Endangered",
    "remarks": "Less than 25 individual trees on Earth"
  },
  {
    "id": 2,
    "scientific_name": "Aqillaria agallocha",
    "common_name": "Agar",
    "icun_assessment": "Critically Endangered",
    "remarks": "One of the most valued sources of Fragrance"
  },
  {
    "id": 3,
    "scientific_name": "Madhuca insignis",
    "common_name": "Suratkal Mahua",
    "icun_assessment": "Critically Endangered (IUCN 3.1)",
    "remarks": "Less than 71 individual Trees left"
  },
  {
    "id": 4,
    "scientific_name": "Nothapodytes nimmoniana",
    "common_name": "Amrith",
    "icun_assessment": "Critically Endangered",
    "remarks": "Camptothecin (CPT), an anti-cancer alkaloid and is the most convenient source for large-scale production of CPT."
  },
  {
    "id": 5,
    "scientific_name": "Canarium strictum",
    "common_name": "Manda -dhupa",
    "icun_assessment": "Vulnerable",
    "remarks": "Source of medicine; incense"
  },
  {
    "id": 6,
    "scientific_name": "Ailanthes triphysa",
    "common_name": "Endemic",
    "icun_assessment": "Endemic"
  },
  {
    "id": 7,
    "scientific_name": "Amoora rohitaka /",
    "icun_assessment": "Vulnerable",
    "remarks": "Highly traded medicinal plant"
  },
  {
    "id": 8,
    "scientific_name": "Cinnamomum malabatrum",
    "common_name": "Endemic",
    "icun_assessment": "Endemic and Vulnerable",
    "remarks": "A source of spice and medicine"
  },
  {
    "id": 9,
    "scientific_name": "Saraca asoka",
    "common_name": "Sita Ashoka",
    "icun_assessment": "Vulnerable",
    "remarks": "Medicinal"
  },
  {
    "id": 10,
    "scientific_name": "Diospyros malabarica ",
    "common_name": "Indian persimmon",
    "icun_assessment": "Endemic",
    "remarks": "Treatment of blood diseases, Gonorrhea and leprosy"
  },
  {
    "id": 11,
    "scientific_name": "Michaelia champaca",
    "common_name": "Champaka",
    "icun_assessment": "Near Threatened",
    "remarks": "Sacred Tree; Source of perfume"
  },
  {
    "id": 12,
    "scientific_name": "Garcinia indica",
    "common_name": "Kokum",
    "icun_assessment": "Endemic and Vulnerable",
    "remarks": "Super food"
  },
  {
    "id": 13,
    "scientific_name": "Artocarpus hirsutus",
    "common_name": "Aini",
    "icun_assessment": "Endemic and vulnerable",
    "remarks": "Source of valuable timber and medicine"
  },
  {
    "id": 14,
    "scientific_name": "Terminalia  chebula",
    "common_name": "Haritha",
    "icun_assessment": "Near Threatened",
    "remarks": "Medicinal"
  },
  {
    "id": 15,
    "scientific_name": "Oroxylum indicum",
    "common_name": "Somyaka",
    "icun_assessment": "Vulnerable",
    "remarks": "Dashamoola Plant"
  },
  {
    "id": 16,
    "scientific_name": "Hydnocarpus pentandrus",
    "common_name": "Suranti ",
    "icun_assessment": "Vulnerable ",
    "remarks": "Medicinal "
  },
  {
    "id": 17,
    "scientific_name": "Adathoda beddomei",
    "common_name": "Malabar nut",
    "icun_assessment": "Endemic",
    "remarks": "Used in Siddha medicines"
  },
  {
    "id": 18,
    "scientific_name": "Commifera guggulu",
    "common_name": "Guggul",
    "icun_assessment": "Endangered",
    "remarks": "Source of incense"
  },
  {
    "id": 19,
    "scientific_name": "Terminalia arjuna ",
    "common_name": "Arjun tree",
    "icun_assessment": "Near threatened",
    "remarks": "Treatment of fractures, ulcers, heart diseases, tumors"
  },
  {
    "id": 20,
    "scientific_name": "Canarium strictum",
    "common_name": "Black dhup",
    "icun_assessment": "Vulnerable",
    "remarks": "Treatment of rheumatism, fever, cough, asthma, epilepsy"
  }
];